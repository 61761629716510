import React from "react";
import Hero from "./Main/Hero"
import HowItWorks from "./Main/How-It-Works"
import Services from "./Main/Services"
import Pricing from "./Main/Pricing"
import Booking from "./Main/Booking"

const Main = () => {
  return (
    <div>
      <Hero />
      {/* <HowItWorks /> */}
      <Services />
      <Pricing />
      <Booking />
    </div>
  );
};

export default Main;
