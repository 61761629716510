
import Header from "../src/Components/Header/Header"
import Main from "../src/Components/Main"
// import Swiper from "../src/Components/Swiper"
import Footer from "../src/Components/Footer"

function App() {
  return (
    <div>
     <Header/>
     <Main/> 
     {/* <Swiper/> */}
     <Footer/>
    </div>
  )
}

export default App;
